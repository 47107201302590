<template>
  <div class="tradingArea">
    <Header :title="'当前城市：' + sendCity" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" name="form" :model="searchData" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" label="区域名称" name="areaSortId">
            <a-select
              allow-clear
              placeholder="请选择区域"
              v-model:value="searchData.areaSortId"
              style="width: 200px"
            >
                <a-select-option v-for="(item,index) in areaList"
                 :key="index" :value="item.id"
                 >{{item.area}}</a-select-option>
              </a-select>
          </a-form-item>
          <a-form-item class="ui-form__item" label="商圈名称" name="name">
            <a-input v-model:value="searchData.name" placeholder="请输入商圈名称" />
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button type="primary" @click="onAdd">新增商圈</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit" style="margin-right: 20px;">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px;"
        :dataSource="dataList"
        :pagination="pagination"
        :columns="columns"
        rowKey="id"
        size="middle"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'coordinate'">
            {{record.xcoordinate}} , {{record.ycoordinate}}
          </template>
          <template v-if="column.dataIndex === 'operate'">
            <div>
              <a-dropdown>
                <a-button @click.prevent>操作
                  <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>
                        <a>编辑</a>
                      </a-menu-item>
                    </div>
                    <div @click="onDelete(record)">
                      <a-menu-item>
                        <a>删除</a>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>

      <a-modal :title="isEdit ? '编辑商圈' : '新增商圈'" v-model:visible="editModalVisible" @ok="onEditOk">
        <a-spin :spinning="loading">
          <a-form ref="editForm" name="editForm" :model="editModelRef" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
            <a-form-item label="区域" name="areaSortId" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-select
                placeholder="请选择区域"
                v-model:value="editModelRef.areaSortId"
                style="width: 200px"
              >
                <a-select-option v-for="(item,index) in areaList"
                 :key="index" :value="item.id"
                 >{{item.area}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商圈" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input style="width: 200px;" v-model:value="editModelRef.name" placeholder="请输入商圈" />
            </a-form-item>
            <a-form-item label="经纬度" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]">
              <div class="ui-custom__btnInput">
                <a-input v-model:value="editModelRef.coordinate" placeholder="请填写地址坐标"></a-input>
                <a-button type="primary" @click="onOpenWin">坐标拾取器</a-button>
						  </div>
            </a-form-item>
            <a-form-item label="优先级" name="sort">
              <a-input-number :min="0" :precision="0" v-model:value="editModelRef.sort" placeholder="请输入优先级" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import Header from '@/components/header/header.vue';
import { 
  getBaseBusinessCircle,addBaseBusinessCircle,
  getBaseAreaSortAllList,updateBaseBusinessCircle,
  deleteBaseBusinessCircle
 } from "@/service/modules/cinema.js"
export default {
  name: "tradingArea",
  components: { Icon, Header },
  props: {
    id: {
      type: Number,
      default: 0
    },
    sendCity: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: false,
      searchData: {},
      areaList: [],
      dataList: [
        {
          region: "天河区",
          tradingArea: "珠江新城",
          locate: "经纬度信息",
          priority: "1"
        },
        {
          region: "天河区",
          tradingArea: "奥体",
          locate: "经纬度信息",
          priority: "2"
        },
        {
          region: "白云区",
          tradingArea: "嘉禾广场",
          locate: "经纬度信息",
          priority: "3"
        }
      ],
      columns: [
        {
          title: "区域",
          dataIndex: "areaSortName",
          width: "20%"
        },
        {
          title: "商圈",
          dataIndex: "name",
          width: "20%"
        },
        {
          title: "经纬度",
          key: "coordinate",
          width: "20%"
        },
        {
          title: "优先级",
          dataIndex: "sort",
          width: "20%"
        },
        {
          title: "操作",
          dataIndex: "operate",
          width: "20%"
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 20,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      isEdit: false,
      editModalVisible: false,
      editModelRef: {
      }
    };
  },
  created(){
    if(this.id) {
      this.getAllArea()
      this.getData()
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
   async getData() {
      this.loading = true
      try {
        const res = await getBaseBusinessCircle({
            page: this.pagination.current,
						pageSize: this.pagination.pageSize,
            citySortId: this.id,
						...this.searchData
        })
        this.loading = false
        if(res.code === 200) {
              this.pagination.total = res.data.totalCount
              this.dataList = res.data.list
        }
      } catch (e) {
        this.loading = false
      }
    },
    onSearch() {
      this.getData()
    },
    onAdd() {
      this.isEdit = false;
      this.editModalVisible = true;
      this.editModelRef = {}
    },
    onEditOk() {
      if(!this.isEdit) {
        this.addBusinessCircle()
      }else {
        this.updateBusinessCircle()
      }
    },
    reset() {
        this.$refs.form.resetFields();
        this.onSearch();
    },
    onEdit(item) {
      let postData = JSON.parse(JSON.stringify(item))
      postData.coordinate = postData.xcoordinate + "," + postData.ycoordinate
      this.editModelRef = postData
      this.isEdit = true;
      this.editModalVisible = true;
    },
    onDelete(item) {
      this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteBaseBusinessCircle({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
			})
    },
    onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
		},
    async getAllArea(){
      this.loading = true
      try {
        const res = await getBaseAreaSortAllList({page:1,pageSize: 99999,citySortId:this.id})
        this.loading = false
        if(res.code === 200) {
          this.areaList = res.data
        }
      } catch (e) {
        this.loading = false
      }
    },
    async addBusinessCircle(){
      this.$refs.editForm.validateFields().then(async () => {
        let postData = JSON.parse(JSON.stringify(this.editModelRef))
        postData.xCoordinate = postData.coordinate.split(",")[0]
        postData.yCoordinate = postData.coordinate.split(",")[1]
        delete postData.coordinate
        this.loading = true;
        try {
          const res = await addBaseBusinessCircle(postData)
          this.loading = false
          if(res.code === 200) {
            this.getData()
            this.$message.success("操作成功")
            this.editModalVisible = false;
          }
        } catch (e) {
          this.loading = false;
        }
      })
    },
    async updateBusinessCircle(){
      this.loading = true;
      try {
        const res = await updateBaseBusinessCircle(this.editModelRef)
        this.loading = false
        if(res.code === 200) {
          this.getData()
          this.$message.success("操作成功")
          this.editModalVisible = false;
        }
      } catch (e) {
          this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 20px;
}

:deep(.ant-input-number) {
  width: 200px;
}

.ui-custom__btnInput {
		position: relative;
}

.ui-custom__btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
</style>