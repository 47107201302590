<template>
  <div class="line">
    <Header :title="'当前城市：' + sendCity" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item label="线路名称" name="name">
            <a-input v-model:value="formState.name" placeholder="请输入线路名称" />
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button type="primary" @click="onAdd">新增线路</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit" style="margin-right: 20px;">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px;"
        :dataSource="list"
        :pagination="pagination"
        :columns="columns"
        rowKey="id"
        size="middle"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <div>
              <a-dropdown>
                <a-button @click.prevent>操作
                  <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>
                        <a>编辑</a>
                      </a-menu-item>
                    </div>
                    <div @click="onDelete(record)">
                      <a-menu-item>
                        <a>删除</a>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>

      <a-modal :title="isEdit ? '编辑线路' : '新增线路'" v-model:visible="editModalVisible" @ok="onEditOk">
        <a-spin :spinning="loading">
          <a-form ref="editForm" name="editForm" :model="editModelRef" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
            <a-form-item label="线路" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input style="width: 200px;" v-model:value="editModelRef.name" placeholder="请输入线路"></a-input>
            </a-form-item>
            <a-form-item label="优先级" name="sort">
              <a-input-number :min="0" :precision="0" v-model:value="editModelRef.sort" placeholder="请输入优先级" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import Header from '@/components/header/header.vue';
import {
  deleteBaseUnderground,
  getBaseUndergroundList,
  saveBaseUnderground,
  updateBaseUnderground
} from "@/service/modules/cinema";
export default {
  name: "lines",
  props: {
    id: {
      type: Number,
      default: 0
    },
    sendCity: {
      type: String,
      default: ''
    },
  },
  components: { Icon, Header },
  data() {
    return {
      lineId: 0,
      loading: false,
      formState: {},
      searchData: {},
      list: [],
      columns: [
        {
          title: "线路",
          dataIndex: "name",
          width: "30%"
        },
        {
          title: "优先级",
          dataIndex: "sort",
          width: "30%"
        },
        {
          title: "操作",
          key: "action",
          width: "30%"
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      isEdit: false,
      editModalVisible: false,
      editModelRef: {

      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getBaseUndergroundList({
          citySortId: this.id,
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onAdd() {
      this.isEdit = false;
      this.editModalVisible = true;
      this.editModelRef = {};
    },
    async onEditOk() {
      this.$refs.editForm.validateFields().then(async () => {
        let ret;
        this.loading = true;
        try {
          if(this.isEdit) {
            ret = await updateBaseUnderground({
              id: this.lineId,
              name: this.editModelRef.name,
              sort: this.editModelRef.sort
            })
          } else {
            ret = await saveBaseUnderground({
              citySortId: this.id,
              name: this.editModelRef.name,
              sort: this.editModelRef.sort
            })
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.editModelRef.name = '';
            this.editModelRef.sort = '';
            this.editModalVisible = false;
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onEdit(item) {
      this.lineId = item.id;
      this.isEdit = true;
      this.editModelRef.name = item.name.slice(0, 1);
      this.editModelRef.sort = item.sort;
      this.editModalVisible = true;
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认删除该线路吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteBaseUnderground({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  }
};
</script>

<style scoped>
:deep(.ant-input-number) {
  width: 200px;
}
</style>