<template>
  <div class="city" v-if="!showSubway && !showTradingArea && !showRegion && !showLine">
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item label="城市名称" name="city">
            <a-input v-model:value="formState.city" placeholder="请输入城市名称" />
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['cinema_cityManage_city_add']" type="primary" @click="onAdd">新增城市</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit" style="margin-right: 20px;">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px;"
        :dataSource="list"
        :pagination="pagination"
        :columns="columns"
        rowKey="id"
        size="middle"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'isDisabled'">
            <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
            <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
          </template>
          <template v-if="column.key === 'hotStatus'">
            {{ record.hotStatus ? '是' : '否' }}
          </template>
          <template v-if="column.key === 'action'">
            <div>
              <a-dropdown>
                <a-button @click.prevent>操作
                  <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['cinema_cityManage_city_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        <a>编辑</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_hot_cancle']" v-if="record.hotStatus === 1" @click="onUpdate(record)">
                      <a-menu-item>
                        取消热门
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_hot_set']" v-else @click="onUpdate(record)">
                      <a-menu-item>
                        设为热门
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_edit_area']" @click="toRegion(record)">
                      <a-menu-item>
                        <a>编辑区域</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_edit_shopping']" @click="toTradingArea(record)">
                      <a-menu-item>
                        <a>编辑商圈</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_edit_site']" @click="toSubway(record)">
                      <a-menu-item>
                        <a>编辑站点</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_edit_line']" @click="toLine(record)">
                      <a-menu-item>
                        <a>编辑线路</a>
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_cityManage_city_del']" @click="onDelete(record)">
                      <a-menu-item>
                        <a>删除城市</a>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>

      <a-modal :title="isEdit ? '编辑城市' : '新增城市'" v-model:visible="editModalVisible" @ok="onEditOk">
        <a-spin :spinning="loading">
          <a-form ref="editForm" name="editForm" :model="editModelRef" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
            <a-form-item label="地区" name="address" :rules="[{required: true, message: '必填项不允许为空'}]">
              <addressSelect style="width: 200px" :value="city" @change="onChangeAddress"></addressSelect>
            </a-form-item>
            <a-form-item label="首字母" name="letter" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input style="width: 200px;" v-model:value="editModelRef.letter" placeholder="请输入首字母" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
  <linee :sendCity="sendCity" :id="id" v-if="showLine" @back="onBackLine"></linee>
  <region :sendCity="sendCity" :id="id" :cityId="cityId" v-if="showRegion" @back="onBackRegion"></region>
  <subway :sendCity="sendCity" :id="id" v-if="showSubway" @back="onBackSubway"></subway>
  <tradingArea :sendCity="sendCity" :id="id" v-if="showTradingArea" @back="onBackTradingArea"></tradingArea>
</template>

<script>
import linee from "../line/line.vue";
import region from "../region/region";
import subway from "../subway/subway.vue";
import tradingArea from "../tradingArea/tradingArea";
import { Icon } from "@/components/icon/icon.js";
import addressSelect from "@/components/addressSelect";
import {deleteBaseCitySort, getBaseCitySortList, saveBaseCitySort, updateBaseCitySort} from "@/service/modules/cinema";

export default {
  components: {
    tradingArea,
    subway,
    region,
    linee,
    addressSelect,
    Icon
  },
  data() {
    return {
      city: [],
      sendCity: '',
      id: 0,
      cityId: 0,
      showLine: false,
      showRegion: false,
      showSubway: false,
      showTradingArea: false,
      loading: false,
      formState: {},
      searchData: {},
      list: [],
      columns: [
        {
          title: "省份",
          dataIndex: "province",
          width: "15%"
        },
        {
          title: "城市",
          dataIndex: "city",
          width: "15%"
        },
        {
          title: "热门城市",
          key: "hotStatus",
          width: "15%"
        },
        {
          title: "首字母",
          dataIndex: "letter",
          width: "15%"
        },
        {
          title: "状态",
          key: "isDisabled",
          width: "15%"
        },
        {
          title: "操作",
          key: "action",
          width: "15%"
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      isEdit: false,
      editModalVisible: false,
      editModelRef: {
        address: []
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onBackLine(isRef) {
      this.showLine = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onBackSubway(isRef) {
      this.showSubway = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onBackRegion(isRef) {
      this.showRegion = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onBackTradingArea(isRef) {
      this.showTradingArea = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getBaseCitySortList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onAdd() {
      this.isEdit = false;
      this.editModalVisible = true;
      this.editModelRef = {};
      this.city = [];
    },
    // 判断首字母是否为大写
    isLetter(str) {
      return /^[A-Z]+$/.test(str);
    },
    async onEditOk() {
      if(!this.editModelRef.address) {
        this.$message.error('请选择城市');
        return;
      }
      if(!this.isLetter(this.editModelRef.letter)) {
        this.$message.error('请输入大写字母');
        return;
      }
      this.editModelRef.provinceId = this.editModelRef.address[0].id;
      this.editModelRef.province = this.editModelRef.address[0].name;
      this.editModelRef.city = this.editModelRef.address[1].name;
      this.editModelRef.cityId = this.editModelRef.address[1].id;
      delete this.editModelRef.address;
      this.loading = true;
      let postData = JSON.parse(JSON.stringify(this.editModelRef))
      try {
        let ret;
        if(this.isEdit) {
          postData.id = this.id
          ret = await updateBaseCitySort(postData)
        } else {
          ret = await saveBaseCitySort(postData)
        }
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.editModelRef = {};
          this.editModalVisible = false;
          this.getData();
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onEdit(item) {
      let postData = JSON.parse(JSON.stringify(item));

      this.city = [];
      let list = [];
      list.push(postData.province);
      list.push(postData.city);
      this.city = JSON.parse(JSON.stringify(list));

      this.editModelRef = postData;

      this.editModelRef.address = [];
      this.editModelRef.address.push({
        id: item.provinceId,
        name: item.province
      })
      this.editModelRef.address.push({
        id: item.cityId,
        name: item.city
      })

      this.isEdit = true;
      this.editModalVisible = true;
      this.id = item.id;
    },
    onUpdate(item) {
      this.$confirm({
        title: '提示',
        content: '确定将该城市' + (item.hotStatus ? '取消热门' : '设为热门') + '吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updateBaseCitySort({
              id: item.id,
              cityId: item.cityId,
              provinceId: item.provinceId,
              city: item.city,
              province: item.province,
              letter: item.letter,
              hotStatus: item.hotStatus ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.hotStatus ? '取消热门' : '设为热门') + '成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该城市吗？',
        onOk: async () => {
          let ret;
          this.loading = true;
          try {
            ret = await updateBaseCitySort({
              id: item.id,
              cityId: item.cityId,
              provinceId: item.provinceId,
              city: item.city,
              province: item.province,
              letter: item.letter,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功')
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    toRegion(item) {
      this.cityId = Number(item.cityId);
      this.id = item.id;
      this.showRegion = true;
      this.sendCity = item.city;
    },
    toTradingArea(item) {
      this.id = item.id
      this.showTradingArea = true;
      this.sendCity = item.city;
    },
    toLine(item) {
      this.id = item.id
      this.showLine = true;
      this.sendCity = item.city;
    },
    toSubway(item) {
      this.id = item.id;
      this.showSubway = true;
      this.sendCity = item.city;
    },
    async onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteBaseCitySort({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功')
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onChangeAddress(e) {
      this.editModelRef.address = e;
    }
  }
};
</script>

<style scoped>

</style>