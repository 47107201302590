<template>
  <div class="region">
    <Header :title="'当前城市：' + sendCity" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" name="form" :model="searchData" @finish="onSearch">
        <a-row>
          <a-form-item label="区域名称" name="area">
            <a-input v-model:value="searchData.area" placeholder="请输入区域名称" allowClear/>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button type="primary" @click="onAdd">新增区域</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit" style="margin-right: 20px;">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px;"
        :dataSource="dataList"
        :pagination="pagination"
        :columns="columns"
        rowKey="id"
        size="middle"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operate'">
            <div>
              <a-dropdown>
                <a-button @click.prevent>操作
                  <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>
                        <a>编辑</a>
                      </a-menu-item>
                    </div>
                    <div @click="onDelete(record)">
                      <a-menu-item>
                        <a>删除</a>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>

      <a-modal :title="isEdit ? '编辑区域' : '新增区域'" v-model:visible="editModalVisible" @ok="onEditOk">
        <a-spin :spinning="loading">
          <a-form ref="editForm" name="editForm" :model="editModelRef" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
            <a-form-item label="区域" name="areaId" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-select
              placeholder="请选择区域"
              v-model:value="editModelRef.areaId"
              style="width: 200px"
              @change="onGetArea"
              >
                <a-select-option v-for="(item,index) in areaList"
                 :key="index" :value="item.id"
                 :title="item.fullname"
                 >{{item.fullname}}</a-select-option>
              </a-select>

            </a-form-item>
            <a-form-item label="优先级" name="sort">
              <a-input-number :min="0" :precision="0" v-model:value="editModelRef.sort" placeholder="请输入优先级" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import Header from '@/components/header/header.vue';
import {
  getDistrictChildren,addBaseAreaSort,
  getBaseAreaSortList,updateBaseAreaSort,
  deleteBaseAreaSort
} from '@/service/modules/cinema.js'
export default {
  name: "region",
  components: { Icon, Header },
  props: {
    id: {
      type: Number,
      default: 0
    },
    cityId: {
      type: Number,
      default: 0
    },
    sendCity: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: false,
      searchData: {},
      dataList: [],
      areaList: [],
      columns: [
        {
          title: "区域",
          dataIndex: "area",
          width: "30%"
        },
        {
          title: "优先级",
          dataIndex: "sort",
          width: "30%"
        },
        {
          title: "操作",
          dataIndex: "operate",
          width: "30%"
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 20,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      isEdit: false,
      editModalVisible: false,
      editModelRef: {}
    };
  },
  created(){
    if(this.id) {
      this.getData()
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true
      try {
        const res = await getBaseAreaSortList({
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.searchData,
        citySortId: this.id
        })
        this.loading = false
        if(res.code === 200) {
            this.dataList = res.data.list
            this.pagination.total = res.data.totalCount
        }
      } catch (e) {
        this.loading = false
      }
    },
    onSearch() {
      this.getData()
    },
    onAdd() {
      this.editModelRef = {}
      this.isEdit = false;
      this.getArea()
      this.editModalVisible = true;
    },
    onEditOk() {
      if(!this.isEdit) {
          this.addAreaBase();
      }else {
          this.updateAreaBase();
      }
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onEdit(item) {
      this.editModelRef = JSON.parse(JSON.stringify(item));
      this.getArea();
      this.isEdit = true;
      this.editModalVisible = true;
    },
    onDelete(item) {
      this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteBaseAreaSort({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
			})
    },
    async getArea(){
      this.loading = true
      try {
        const res = await getDistrictChildren({id: this.cityId})
        this.loading = false
        if(res.code === 200) {
          this.areaList = res.data[0]
        }
      } catch (error) {
        this.loading = false
      }
    },
    async addAreaBase() {
      this.$refs.editForm.validateFields().then(async () => {
        this.loading = true
        try {
          const res = await addBaseAreaSort({
            citySortId: this.id,
            ...this.editModelRef
          })
          this.loading = false
          if(res.code === 200) {
            this.$message.success("操作成功")
            this.getData()
            this.editModalVisible = false
            this.editModelRef = {}
          }
        } catch (e) {
          this.loading = false
        }
      })
    },
    async updateAreaBase() {
      this.loading = true
      try {
        const res = await updateBaseAreaSort(this.editModelRef)
        this.loading = false
        if(res.code === 200) {
          this.$message.success("操作成功")
          this.getData()
          this.editModalVisible = false
          this.editModelRef = {}
        }
      } catch (e) {
        this.loading = false
      }
    },
    onGetArea(val, area){
      this.editModelRef.area = area.title
    }
  }
};
</script>

<style scoped>
:deep(.ant-input-number) {
  width: 200px;
}
</style>
