<template>
  <div class="subway">
    <Header :title="'当前城市：' + sendCity" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" label="线路名称" name="undergroundName">
            <a-input v-model:value="formState.undergroundName" placeholder="请输入线路名称" />
          </a-form-item>
          <a-form-item class="ui-form__item" label="站点名称" name="name">
            <a-input v-model:value="formState.name" placeholder="请输入站点名称" />
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button type="primary" @click="onAdd">新增站点</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit" style="margin-right: 20px;">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px;"
        :dataSource="list"
        :pagination="pagination"
        :columns="columns"
        rowKey="id"
        size="middle"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'coordinate'">
            {{ record.xcoordinate + ',' + record.ycoordinate }}
          </template>
          <template v-if="column.key === 'action'">
            <div>
              <a-dropdown>
                <a-button @click.prevent>操作
                  <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>
                        <a>编辑</a>
                      </a-menu-item>
                    </div>
                    <div @click="onDelete(record)">
                      <a-menu-item>
                        <a>删除</a>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>

      <a-modal :title="isEdit ? '编辑站点' : '新增站点'" v-model:visible="editModalVisible" @ok="onEditOk">
        <a-spin :spinning="loading">
          <a-form ref="editForm" name="editForm" :model="editModelRef" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
            <a-form-item label="线路" name="undergroundId" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-select
              placeholder="请选择线路"
              v-model:value="editModelRef.undergroundId"
              style="width: 200px"
              >
                <a-select-option v-for="(item,index) in lineList"
                 :key="index" :value="item.id"
                 >{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="站点" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input style="width: 200px;" v-model:value="editModelRef.name" placeholder="请输入站点" />
            </a-form-item>
            <a-form-item label="经纬度" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]">
              <div class="ui-custom__btnInput">
                <a-input v-model:value="editModelRef.coordinate" placeholder="请填写地址坐标"></a-input>
                <a-button class="ui-custom__btn" type="primary" @click="onOpenWin">坐标拾取器</a-button>
						  </div>
            </a-form-item>
            <a-form-item label="优先级" name="sort">
              <a-input-number :min="0" :precision="0" v-model:value="editModelRef.sort" placeholder="请输入优先级" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import Header from '@/components/header/header.vue';
import {
  deleteBaseUnderground, deleteBaseUndergroundSite,
  getBaseUndergroundGetAll,
  getBaseUndergroundSiteList, saveBaseUndergroundSite,
  updateBaseUndergroundSite
} from "@/service/modules/cinema";
export default {
  name: "subway",
  components: { Icon, Header },
  props: {
    id: {
      type: Number,
      default: 0
    },
    sendCity: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      subwayId: 0,
      loading: false,
      formState: {},
      searchData: {},
      lineList: [],
      list: [],
      columns: [
        {
          title: "线路",
          dataIndex: "undergroundName",
          width: "20%"
        },
        {
          title: "站点",
          dataIndex: "name",
          width: "20%"
        },
        {
          title: "经纬度",
          key: "coordinate",
          width: "20%"
        },
        {
          title: "优先级",
          dataIndex: "sort",
          width: "20%"
        },
        {
          title: "操作",
          key: "action",
          width: "20%"
        }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 20,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      isEdit: false,
      editModalVisible: false,
      editModelRef: {

      },
    };
  },
  created() {
    if(this.id) {
      this.getAllLine()
      this.getData()
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getBaseUndergroundSiteList({
          citySortId: this.id,
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onAdd() {
      this.isEdit = false;
      this.editModalVisible = true;
      this.editModelRef = {};
    },
    async onEditOk() {
      this.$refs.editForm.validateFields().then(async () => {
        this.loading = true;
        let postData = JSON.parse(JSON.stringify(this.editModelRef));
        postData.xCoordinate = postData.coordinate.split(",")[0];
        postData.yCoordinate = postData.coordinate.split(",")[1];
        delete postData.coordinate;
        try {
          let ret;
          if(this.isEdit) {
            postData.undergroundId = this.subwayId;
            ret = await updateBaseUndergroundSite(postData)
          } else {
            ret = await saveBaseUndergroundSite(postData)
          }
          if(ret.code === 200) {
            this.editModelRef = {};
            this.editModalVisible = false;
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onEdit(item) {
      this.isEdit = true;
      this.editModalVisible = true;
      this.subwayId = item.id;
      let postData = JSON.parse(JSON.stringify(item));
      postData.coordinate = postData.xcoordinate + "," + postData.ycoordinate;
      this.editModelRef = postData;
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认删除该站点吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteBaseUndergroundSite({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
		},
    async getAllLine() {
      this.loading = true;
      try {
        let ret = await getBaseUndergroundGetAll({
          citySortId: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          this.lineList = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 20px;
}

:deep(.ant-input-number) {
  width: 200px;
}

.ui-custom__btnInput {
		position: relative;
}

.ui-custom__btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
</style>