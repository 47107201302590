<template>
    <a-spin :spinning="loading">
        <a-cascader :disabled="disabled" v-model:value="city" :fieldNames="fieldNames" :options="options" @change="onChange" placeholder="请选择" />
    </a-spin>
</template>

<script>
    import { getDivision } from "@/service/modules/system";
    export default {
        props: {
            value: {
                type: Array,
                default: () => []
            },
			disabled: {
				type: Boolean,
				default: false
			},
			level: {
				type: Number,
				default: 2
			}
        },
        data() {
            return {
				loading: false,
                fieldNames: {
                    label: 'fullname',
                    value: 'id',
                    children: 'sub'
                },
                city: [],
                options: []
            }
        },
        watch: {
          value(newVal) {
            this.city = JSON.parse(JSON.stringify(this.value));
          }
        },
        created() {
            this.getDivision();
        },
        methods: {
            getDivision() {
				this.loading = true;
                getDivision({}).then(res => {
                    res.data = res.data.filter(item => item.level !== this.level);
                    this.options = res.data.filter(function (item) {
                      // console.log(res.data)
                        item.sub = res.data.filter(function (childItem) {
                            return (item.id == childItem.parentId)
                        })
                        return item.level == 0;
                    });
                  this.city = JSON.parse(JSON.stringify(this.value));
				  this.loading = false;
                })
            },
            onChange(value, selectedOptions) {
                this.$emit('update:value', value);
                this.$emit('change', selectedOptions);
            }
        },
    }
</script>